<template>
  <div>
    <CCardBody class="c-card-body">
      <h2>
        Статистика участвующих (всего
        {{ numberFormat(participantsStatsJson.total) }})
      </h2>
      <br />
      <div class="row" style="min-height: 30px">
        <div class="col-2">Не начали тест</div>
        <div class="col-6">
          <div class="progress">
            <div
              class="progress-bar bg-danger"
              :style="`width: ${startPercent}%`"
              role="progressbar"
              :aria-valuenow="startPercent"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <div class="col-3">
          {{ numberFormat(participantsStatsJson.start) }} ({{
            numberFormat(startPercent)
          }}%)
        </div>
      </div>
      <div class="row" style="min-height: 30px">
        <div class="col-2">Не прошли тест</div>
        <div class="col-6">
          <div class="progress">
            <div
              class="progress-bar"
              :style="`width: ${quizPercent}%`"
              role="progressbar"
              :aria-valuenow="quizPercent"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <div class="col-3">
          {{ numberFormat(participantsStatsJson.quiz) }} ({{
            numberFormat(quizPercent)
          }}%)
        </div>
      </div>
      <div class="row" style="min-height: 30px">
        <div class="col-2">Прошли тест</div>
        <div class="col-6">
          <div class="progress">
            <div
              class="progress-bar bg-success"
              :style="`width: ${horoscopePercent}%`"
              role="progressbar"
              :aria-valuenow="horoscopePercent"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <div class="col-3">
          {{ numberFormat(participantsStatsJson.horoscope) }} ({{
            numberFormat(horoscopePercent)
          }}%)
        </div>
      </div>
      <div class="row" style="min-height: 30px">
        <div class="col-2">Получат Счастливую карту завтра</div>
        <div class="col-6">
          <div class="progress">
            <div
              class="progress-bar bg-success"
              :style="`width: ${canGetLuckyCardPercent}%`"
              role="progressbar"
              :aria-valuenow="canGetLuckyCardPercent"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <div class="col-3">
          {{ numberFormat(participantsStatsJson.can_get_lucky_card) }} ({{
            numberFormat(canGetLuckyCardPercent)
          }}%)
        </div>
      </div>
      <CRow class="mt-2">
        <CCol sm="12" lg="3">
          <CWidgetSimple
            color="primary"
            header="Открыли одно предсказание за всё время"
            :text="numberFormat(participantsStatsJson.init_horoscope_1)"
          >
          </CWidgetSimple>
        </CCol>
        <CCol sm="12" lg="3">
          <CWidgetSimple
            header="Открыли два предсказания за всё время"
            :text="numberFormat(participantsStatsJson.init_horoscope_2)"
          >
          </CWidgetSimple>
        </CCol>
        <CCol sm="12" lg="3">
          <CWidgetSimple
            header="Не открыли предсказание, но прошли тест"
            :text="numberFormat(participantsStatsJson.init_horoscope_0)"
          >
          </CWidgetSimple>
        </CCol>
      </CRow>

      <CRow>
        <CCol col="12" sm="6">
          <CWidgetBrand
            color=""
            :rightHeader="
              numberFormat(participantsStatsJson.unique_result_share)
            "
            rightFooter="Результат теста"
            :leftHeader="numberFormat(participantsStatsJson.unique_lucky_card_share)"
            leftFooter="Счастливая карта"
            class="w-100"
          >
            <div class="card-header-main">Уникальные попытки шера</div>
          </CWidgetBrand>
        </CCol>
      </CRow>

      <CRow>
        <CCol col="12" sm="6">
          <CWidgetBrand
            color=""
            :rightHeader="
              numberFormat(participantsStatsJson.success_result_share)
            "
            rightFooter="Результат теста"
            :leftHeader="numberFormat(participantsStatsJson.success_lucky_card_share)"
            leftFooter="Счастливая карта"
            class="w-100"
          >
            <div class="card-header-in">Успешные попытки шера</div>
          </CWidgetBrand>
        </CCol>
      </CRow>
      <br />
    </CCardBody>
  </div>
</template>

<style scoped>
.card {
  border: none !important;
}

.card-header {
  border: none;
}

.card-header-main {
  background: rgb(50, 31, 219);
  width: 100%;
  min-height: 50px;
  display: grid;
  align-content: center;
  font-size: 18px;
}
.card-header-in {
  background: rgb(47, 143, 232);
  width: 100%;
  min-height: 50px;
  display: grid;
  align-content: center;
  font-size: 18px;
}
</style>

<style>
.test {
  background-color: red;
}
.c-card-body {
  background-color: #fefefe;
}
table {
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  font-size: 14px;
  background: white;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}
th {
  font-weight: normal;
  color: #039;
  border-bottom: 2px dashed #6678b1;
  padding: 10px 8px;
}
.td {
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}
tr:hover .td {
  background: #e8edff;
}
.td {
  border-bottom: 0.5px dashed #ccc;
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}
th:nth-child(-n + 3),
.td:nth-child(-n + 3) {
  text-align: left;
}
.item-right {
  text-align: right;
}
</style>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ParticipantsStats",
  data() {
    return {
      sumOfParticipants: 0,
      startPercent: 0,
      quizPercent: 0,
      horoscopePercent: 0,
      canGetLuckyCardPercent: 0,
    };
  },
  computed: {
    ...mapState({
      participantsStatsJson(state) {
        this.startPercent = this.getPercentValue(
          state.participants.stats_json.start,
          state.participants.stats_json.total
        );
        this.quizPercent = this.getPercentValue(
          state.participants.stats_json.quiz,
          state.participants.stats_json.total
        );
        this.horoscopePercent = this.getPercentValue(
          state.participants.stats_json.horoscope,
          state.participants.stats_json.total
        );
        this.canGetLuckyCardPercent = this.getPercentValue(
          state.participants.stats_json.can_get_lucky_card,
          state.participants.stats_json.total
        );
        return state.participants.stats_json;
      },
    }),
  },
  created() {
    this["participants/getStatsJson"]();
  },
  methods: {
    ...mapActions(["participants/getStatsJson"]),
    getPercentValue(value, maxValue) {
      return ((value * 100) / maxValue).toFixed(2);
    },
    numberFormat(value) {
      return new Intl.NumberFormat("ru-RU").format(value);
    },
  },
};
</script>
